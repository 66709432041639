'use strict';

angular.module('enervexSalesappApp').controller('VentbomProjectsCtrl', function($scope, $stateParams, Account, VentbomProject,_, Auth, Flash,$q) {
	$scope.accountId = $stateParams.accountId;
	$scope.all = [];
	$scope.offset = 0;
	$scope.limit = 100;
	$scope.isAdmin = Auth.isAdmin;
	$scope.ctx = {
		httpLoading: false,
		term: '',
	}
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});

	function fetchProjects() {
		VentbomProject.query({
			offset:$scope.offset,
			limit: $scope.limit,
			accountId: $scope.accountId
		}).$promise.then(function(result){
			if (result.length > 0) {
				$scope.all = $scope.all.concat(result);
				$scope.offset = $scope.offset + $scope.limit;
				fetchProjects();
			} else {
				$scope.selectFilter();
				$scope.ctx.httpLoading = false
			}
		});
	}
	fetchProjects();
	$scope.selectFilter = function() {
		var regex = null;
		if ($scope.ctx.term && $scope.ctx.term != ""){
			regex = new RegExp($scope.ctx.term, "i")
		}
		$scope.projects = _.filter($scope.all, function(item){
			if (regex && !(regex.test(item.VBProjectNo) || regex.test(item.JobName) || regex.test(item.ByUser) || regex.test(item.RepRefNo))) {
				return false
			} else {
				return true
			}
		})
	}
	$scope.sync = function(project) {
		VentbomProject.syncOne({
			id: project._id,
			accountId: $stateParams.accountId
		}).$promise.then(function(response) {
			console.log('success', response)
			
			$scope.success = true;
			var url = "/accounts/"+response.job.account +"/jobs/"+response.job._id;
			var message = '<strong>Success!</strong> You successfully synced ' + response.job.name + ', <a href="'+url+ '">check it out</a>.';
			Flash.create('success', message, 0, {}, true);
		}).catch(function(err){
			console.log('err', err)
			
			$scope.errors = {};
			$scope.errors.other = err.message;
			var message = err.message || "There as an error syncing the fancalc job";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		})
	}
});
